import styles from './adultsPage.module.css';
import './menu.css';
import '../../Common.css';
import danceStyles from '../../Data/Adults/StylesAdults';
import coaches from '../../Data/Adults/CoachesAdults';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { slide as Menu } from 'react-burger-menu'
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import YoutubeModalComponent from '../YoutubeModal/YoutubeModalComponent';
import RequestModelComponent from '../RequestModal/RequestModelComponent';

const AdultsPage = () => {

  useEffect(() => {
    document.title = 'DEMARCHE CENTRE / Взрослые';
  }, []);

  const aboutUsRef = useRef();
  const stylesRef = useRef();
  const coachesRef = useRef();
  const hallsRef = useRef();
  const pricesRef = useRef();
  const galeryRef = useRef();
  const feedbackRef = useRef();
  const contactRef = useRef();

  let navigate = useNavigate();

  const [isBranchEnergyActive, setIsBranchEnergyActive] = useState(false);
  const [isBranchImpulseActive, setIsBranchImpulseActive] = useState(true);
  const [isBranchLifeActive, setIsBranchLifeActive] = useState(false);

  const [youtubeCoachModalVisible, setYoutubeCoachModalVisible] = useState(false);
  const [youtubeCoachUrl, setYoutubeCoachUrl] = useState();
  const [youTubeName, setYouTubeName] = useState();


  const [showRequestModal, setShowRequestModal] = useState(false);

  const scrollFunc = (ref) => {
    window.scrollTo({ behavior: 'smooth', top: ref?.current?.offsetTop });
  }

  const menuSettings = (event) => {
    event.preventDefault();
  }

  const [coachesDetails, setСoachesDetails] = useState(coaches.map((item) => { return {...item, detailsShow: false }; }));

  const handleYoutubeClick = (videoUrl, name) => {
    setYoutubeCoachUrl(videoUrl);
    setYoutubeCoachModalVisible(true);
    setYouTubeName(name ?? '');
  };

  const handleCoachDetailsClick = index => {
    const coachesDetailsNext = coachesDetails.map((coach, idx) => { 
      if (index === idx) 
        coach.detailsShow = !coach.detailsShow;
      return coach });

    setСoachesDetails(coachesDetailsNext);
  }

  const handleClose = () => setShowRequestModal(false);

  const handleHallsBranchClick = (event) => {
    const branchId = event?.target?.id;
    if (branchId)
    {
      switch(branchId){
        case "Life":
          setIsBranchLifeActive(true);
          setIsBranchImpulseActive(false);
          setIsBranchEnergyActive(false);
          return;
        case "Impulse":
          setIsBranchLifeActive(false);
          setIsBranchImpulseActive(true);
          setIsBranchEnergyActive(false);
          return;
        case "Energy":
          setIsBranchLifeActive(false);
          setIsBranchImpulseActive(false);
          setIsBranchEnergyActive(true);
          return;
        default:
          setIsBranchLifeActive(true);
          setIsBranchImpulseActive(false);
          setIsBranchEnergyActive(false);
          return;
      }
    }
  }

  return (
  <div className={styles.custom_container}>
    <RequestModelComponent show={showRequestModal} handleClose={handleClose}></RequestModelComponent>
    <YoutubeModalComponent url={youtubeCoachUrl} show={youtubeCoachModalVisible} handleClose={() => setYoutubeCoachModalVisible(false)} name={youTubeName} />
    <div>
      <Menu right >
          <span id="about" className="menu-item" onClick={() => navigate("/")}>Главная</span>
          <span id="about" className="menu-item" onClick={() => scrollFunc(aboutUsRef)}>Почему мы</span>
          <span id="styles" className="menu-item"  onClick={() => scrollFunc(stylesRef)}>Направления</span>
          <span id="coaches" className="menu-item" onClick={() => scrollFunc(coachesRef)}>Тренеры</span>
          <span id="prices" className="menu-item" onClick={() => scrollFunc(pricesRef)}>Цены</span>
          <span id="halls" className="menu-item" onClick={() => scrollFunc(hallsRef)}>Залы</span>
          <span id="galery" className="menu-item" onClick={() => scrollFunc(galeryRef)}>Галерея</span>
          <span id="galery" className="menu-item" onClick={() => scrollFunc(feedbackRef)}>Отзывы</span>
          <span id="contact" className="menu-item" onClick={() => scrollFunc(contactRef)}>Контакты</span>
          <div>
            <a href="/"><img className={styles.logo_dm_white_menu} src="images/logo_dm_white.png" alt=""/></a>
          </div>
      </Menu>
    </div>
    <div className={`${styles.row} ${styles.layer_6}`}>
    {/* Мобильная версия  */}
    <div className={`row d-block d-md-none`}>
    <div className="row">
                <div> 
                  <img className={styles.ellipse} src="images/ellipse.png" alt=""/>
                </div>
                <div> 
                  <img className={styles.home_main_image_mobile} src="images/main-image.png" alt=""/>
                </div>
            </div>
            <div className="row justify-content-center">
                  <div className="col-md-12">
                    <img className={`${styles.tancui}`} src="images/tancui.png" alt="ТАНЦУЙ" title="ТАНЦУЙ"/>
                  </div>
                  <div className="col-md-12">
                    <img className={`${styles.kruche}  ${styles.mt_70p}`} src="images/kruche.png" alt="КРУЧЕ!" title="КРУЧЕ!"/>
                  </div>
            </div>
            <div className="row justify-content-center">
                  <div className="col-md-12 justify-content-center">
                      <span className={styles.zapisatsya} onClick={() => setShowRequestModal(true) }>ЗАПИСАТЬСЯ</span>
                  </div>
                </div>
          </div>
     {/* Десктоп версия  */}
    <div className={`row d-none d-md-block`}>
    <div className="row">
        <div className={`row ${styles.mt_70p} justify-content-center`}>
            <div className="col col-md-4">
              <div>
                <a href="/"><img className={styles.logo_dm_white_header} src="images/logo_dm_white.png" alt=""/></a>
              </div>
            </div>
            <div className="col col-md-4">
              
            </div>
            <div className="col col-md-3">
            </div>
        </div>
    </div>
    <div className="col col-md-6"> 
          <div> 
            <img className={styles.ellipse} src="images/ellipse.png" alt=""/>
          </div>
          <div> 
            <img className={styles.home_main_image} src="images/main-image.png" alt=""/>
          </div>           
        </div>
        <div className={`${styles.text_area} col col-md-6`}>
          <div className="row justify-content-center">
            <div className="col-md-8">
                <span className={styles.zapisatsya} onClick={() => setShowRequestModal(true)}>ЗАПИСАТЬСЯ</span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8">
              <img className={styles.tancui} src="images/tancui.png" alt="ТАНЦУЙ" title="ТАНЦУЙ"/>
              <img className={styles.kruche} src="images/kruche.png" alt="КРУЧЕ!" title="КРУЧЕ!"/>
            </div>
          </div>
          <div className="row mt-15 d-none d-lg-block">
            <div className="row justify-content-center">
              <div className="col-md-12">
                <div className={styles.text}>Центр танцев DEMARCHE</div>
                <p className={styles.body_text}>Место, где взрослые танцуют легко, стильно, с удовольствием и огнем в сердце! Мы создаём танцевальное движение, вовлекаем в активный образ жизни и помогаем прокачать танцевальные таланты.</p>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-12">
                <div className={styles.text}>Даже если вы никогда не танцевали раньше</div>
                <p className={styles.body_text}>Или имеете совсем небольшой dance-опыт – здесь вы полностью раскроете свой танцевальный потенциал и научитесь жить в ритме <strong className="fw900">Jazz-Funk</strong> или <strong className="fw900">HIP-HOP</strong>. Выбирайте из 10 самых крутых современных направлений <strong className="fw900">#ДляТела, #ДляДуши и #ДляСтиля!</strong></p>
                <a className={styles.arrow} src="images/arrow.png"></a>
              </div>
            </div>
          </div>
        </div>

              
              {/* <div className="menu">
                <nav className="nav">
                  <ul className="nav-list group">
                    <li><p className="nav-item-1"><a >О НАС</a></p></li>
                    <li><p className="nav-item-1-2"><a >НАПРАВЛЕНИЯ</a></p></li>
                    <li><p className="nav-item-1-3"><a >ТРЕНЕРЫ</a></p></li>
                    <li><p className="nav-item-1-4"><a >ЦЕНЫ</a></p></li>
                    <li><p className="nav-item-1-5"><a >РАСПИСАНИЕ</a></p></li>
                    <li><p className="nav-item-1-6"><a >ГАЛЕРЕЯ</a></p></li>
                    <li><p className="nav-item-1-7"><a >КОНТАКТЫ</a></p></li>
                  </ul>
                </nav>
                <p className="zapisatsya">ЗАПИСАТЬСЯ</p>
              </div> */}



          {/* <div className="row">
            <div className="col-md-12">
              <img className="logo-dm_white" src="images/logo_dm_white.png" alt="" width="30%"/>
              <p className="text">Центр танцев DEMARCHE</p>
              <p className="body-text">Место, где взрослые танцуют легко, стильно, с удовольствием и огнем в сердце! Мы создаём танцевальное движение, вовлекаем в активный образ жизни и помогаем прокачать танцевальные таланты.</p>
              <p className="text-2">Даже если вы никогда не танцевали раньше</p>
              <p className="body-text-copy">Или имеете совсем небольшой dance-опыт – здесь вы полностью раскроете свой танцевальный потенциал и научитесь жить в ритме <strong className="fw900">Jazz-Funk</strong> или <strong className="fw900">HIP-HOP</strong>. Выбирайте из 10 самых крутых современных направлений <strong className="fw900">#ДляТела, #ДляДуши и #ДляСтиля!</strong></p>
            </div>
          </div> */}
      </div>
    </div>

            <div ref={aboutUsRef} className={styles.pochemu_my}>
              <div className="row justify-content-center">
                <div className={`${styles.underline_margin_check} ${styles.title}`}>Почему мы</div>
              </div>
              <div className="row">
              {/* <div className="pochemu-my"> */}
                {/* <div className="wrapper-9"> */}
                  {/* <img className="text-5" src="images/pochemu_my.png" alt="почему мы?" width="639" height="62" title="почему мы?"/> */}
                  <div className="col-md-4">
                    <div className={styles.about_us_item}>
                            <p className={styles.text_6}>ТАНЦЫ ДЛЯ КАЖДОГО</p>
                            <p className={styles.text_7}>Подберем группу вне зависимости от вашего уровня подготовки и возраста – с нуля до профи.</p>
                    </div>
                    <div className={styles.about_us_item}>
                            <p className={styles.text_6}>Адекватные цены</p>
                            <p className={styles.text_7}>Мы хотим, чтобы вам нравилось танцевать с нами, поэтому предлагаем гибкую систему скидок и безлимитные абонементы на любые занятия в Центре.</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <img className={styles.tantsutsykruche} src="images/tantsutsykruche-1.png" alt=""/>
                  </div>
                  <div className="col-md-4">
                    <div className={styles.about_us_item}>
                        <p className={styles.text_6}>Топовые хореографы</p>
                        <p className={styles.text_7}>Наши тренеры – чемпионы европейских соревнований, участники TV-проектов <strong className="fw900">«ТАНЦЫ НА ТНТ»</strong> и <strong className="fw900">«Dance Revolution».</strong></p>
                      </div>
                      <div className={styles.about_us_item}>
                        <p className={styles.text_6}>Удобный график</p>
                        <p className={styles.text_7}>Танцуйте, когда вам хочется – есть утренние, вечерние группы, занятия по выходным.</p>
                      </div>
                  </div>

                  
                  {/* <div className="row-2">
                    <div className="l-constrained-2 group">
                      <div className="col-3">
                       
                      </div>
                      <div className="col-34">
                        <div className="rectangle-4"></div>
                        <div className="row-25 group">
                          <div className="col-38">
                            <div className="komanda-topovyh">
                              <p className="text-10">Команда топовых<br/>хореографов</p>
                              <p className="text-11">Наши тренеры – чемпионы европейских соревнований, участники TV-проектов <strong className="fw900">«ТАНЦЫ НА ТНТ»</strong> и <strong className="fw900">«Dance Revolution».</strong></p>
                            </div>
                            <div className="udobnyi-grafik">
                              <p className="text-12">Удобный график</p>
                              <p className="text-13">Танцуйте, когда вам хочется – есть утренние, вечерние группы, занятия по выходным.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="rectangle-7-holder">
                    <img className="cap" src="images/cap.jpg" alt="" width="1406" height="541"/>
                  </div>
                  <div className="komfortnoe-prostranstvo">
                    <p className="text-14">Комфортное пространство</p>
                    <p className="text-15">Большим танцам нужна большая площадь! <br/>У нас 4 оборудованных зала для разных групп и индидов, просторный холл, уютный бар.</p>
                    <p className="text-16">И мы создаем собственный мерч – танцорам от танцоров!</p>
                    <div className="layer-holder">
                      <div className="slider group">
                        <div className="ellipse-3"></div>
                        <div className="ellipse-3-copy"></div>
                        <div className="ellipse-3-copy-2"></div>
                        <div className="ellipse-3-copy-3"></div>
                        <div className="ellipse-3-copy-4"></div>
                      </div>
                    </div>
                    <img className="shadow-copy" src="images/shadow_copy.png" alt="" width="3438" height="92"/>
                  </div>
                  <div className="lights group">
                    <img className="layer-6" src="images/layer_6.png" alt="" width="1173" height="1908"/>
                    <img className="layer-6-copy" src="images/layer_6_copy.png" alt="" width="968" height="1594"/>
                  </div>
                  <img className="text-box-copy" src="images/text_box_copy.png" alt="" width="1039" height="166"/> */}
                {/* </div> */}
                {/* <img className="shadow-copy-2" src="images/shadow_copy.png" alt="" width="3440" height="123"/> */}
              {/* </div> */}
              </div>
            </div>
            <div ref={stylesRef} className={styles.l_unconstrained}>
            <div className="row justify-content-center">
                      <div className={`${styles.underline_margin_check} ${styles.title}`}>Направления</div>
                    </div>
                    <div className={`row ${styles.styles}`}>
                      {danceStyles.map((style) =>
                        <div className="col-md-3">
                            <div class={styles.styles_youtube_icon}>
                              <img class={styles.styles_free_icon_youtube} onClick={() => handleYoutubeClick(style.videoUrl, style.name) } src="images/free-icon-youtube-1077046.png" alt="" width="39" height="29"/>
                            </div>
                              <div>
                                <div className={styles.styles_title}>
                                  <div>{style.name}</div>
                                </div>
                              </div>
                              <p className={styles.style_description}>{style.desciption}</p>
                        </div>
                      )}
                    </div>
            </div>
            
            <div ref={coachesRef} className={styles.l_unconstrained}>
            <div className="row justify-content-center">
              <div className={`${styles.underline_margin_check} ${styles.title}`}>ТРЕНЕРЫ</div>
            </div>
            <div className={`row ${styles.styles} ${styles.mt_70p}`}>
              {coachesDetails.map((coach, index) =>
                <div className="col-md-3">
                  <div className={`${styles.coach_background} row justify-content-center col-15 mb_70px`}>
                        <img className={styles.coach} src={coach.photo}></img>
                        <div className="row">
                          <div className={styles.coach_style}>{coach.style}</div>
                        </div>
                        <div className="row justify-content-center">
                          <div className="col-3">
                          <span><img className={`${styles.coach_icon_youtube}`} onClick={() => handleYoutubeClick(coach.videoUrl)} src="images/free-icon-youtube-1077046_11.png" alt=""/></span>
                          </div>
                          <div className={`${styles.coach_name} col-6`}>
                            {coach.surname}<br/>{coach.name}
                          </div>
                          <div className={`${styles.coach_icon_plus} col-3`}>
                            <span onClick={() => handleCoachDetailsClick(index)}>{coach.detailsShow ? '-' : '+'}</span>
                          </div>
                        </div>
                        { coach.detailsShow && <div className="row justify-content-center">
                          <div className={`${styles.coach_description}`}>
                            {coach.description.split("•").map((item) => 
                              <p>{item}</p>
                            )}
                          </div>
                        </div> }
                        {/* </Accordion.Body> */}
                    </div>
                </div>
              )}
            </div>
   
            </div>
            <div ref={pricesRef} className={styles.l_unconstrained}>
              <div className="row">
                <div className={`${styles.title} ${styles.underline_margin_check}`}>Цены</div>
              </div>
              <div className={`${styles.price_area}`}>
                 {/* Десктоп версия  */}
                <div className={`row d-none d-lg-block`}>
                <div className="row">
                    <div className="col col-sm-3">
                      <div className="row">
                          <div className="col col-sm-2">
                              <div className={`${styles.group_5}`}>
                                <img className={`${styles.ellipse_5}`} src="images/ellipse_5.png" alt=""/>
                                <img className={`${styles.rectangle_15}`} src="images/rectangle_blue.png" alt=""/>
                              </div>
                          </div>
                          <div className="col col-sm-8">
                              <div className={`${styles.price_title}`}>
                              <div>ПРОБНЫЕ/</div>
                              <div>РАЗОВЫЕ</div>
                              <div>ЗАНЯТИЯ</div></div> 
                              <div className={`${styles.price_text_1}`}>
                              <div className={`${styles.fw100}`}>Пробное</div>
                              <div className={`${styles.fw100}`}>занятие</div>
                              <div className={`${styles.price_price}`}>60 мин. - 350 руб.</div>
                              <div class="text-style-5">&nbsp;</div>
                              <div className={`${styles.fw100}`}>Пробное</div>
                              <div className={`${styles.fw100}`}>занятие</div>
                              <div className={`${styles.price_price}`}>90-120 мин. - 450 руб.</div>
                              <div class="text-style-5">&nbsp;</div>
                              <div className={`${styles.fw100}`}>Разовое</div>
                              <div className={`${styles.fw100}`}>занятие</div>
                              <div className={`${styles.price_price}`}>60 мин. - 900 руб.</div>
                              <div class="text-style-5">&nbsp;</div>
                              <div className={`${styles.fw100}`}>Разовое</div>
                              <div className={`${styles.fw100}`}>занятие</div>
                              <div className={`${styles.price_price}`}>90 мин. - 1350 руб.</div>
                              </div>
                          </div>
                          <div className="col col-sm-2">
                              <div className={`${styles.group_5_copy}`}>
                                <img className={`${styles.rectangle_15_2}`} src="images/rectangle_blue.png" alt=""/>
                                <img className={`${styles.ellipse_5_2}`} src="images/ellipse_5_2.png" alt=""/>
                              </div>
                          </div>
                      </div>
                    </div>

                    <div className="col col-sm-3">
                      <div className="row">
                        <div className="col col-sm-2">
                            <div className={`${styles.group_5}`}>
                              <img className={`${styles.ellipse_5}`} src="images/ellipse_5.png" alt=""/>
                              <img className={`${styles.rectangle_15}`} src="images/rectangle_green.png" alt=""/>
                            </div>
                        </div>
                        <div className="col col-sm-8">
                        <p className={`${styles.price_title}`}>
                          <div>АБОНЕМЕНТЫ </div>
                            </p>
                            <div className={`${styles.fw100}`}>длительность занятий</div>
                            <div className={`${styles.fw100}`}>1 час</div>
                            <br/>
                            <p className={`${styles.price_text_1}`}>
                            <div className={`${styles.fw100}`}>4 занятия</div>
                            <div className={`${styles.price_price}`}>3200 руб.</div>
                            <div class="text-style-5">&nbsp;</div>
                            <div className={`${styles.fw100}`}>8 занятий</div>
                            <div className={`${styles.price_price}`}>4900 руб.</div>
                            <div className={`${styles.price_comments}`}>срок действия абонемента <b>30 дней</b></div>
                            <div className={`${styles.price_comments}`}>на выбор <b>1 направление</b></div>
                            <div class="text-style-5">&nbsp;</div>
                            <div className={`${styles.fw100}`}>12 занятий</div>
                            <div className={`${styles.price_price}`}>6900 руб.</div>
                            <br/>
                            <div className={`${styles.price_comments}`}>срок действия абонемента <b>60 дней</b></div>
                            <div className={`${styles.price_comments}`}>на выбор <b>2 направление</b></div>
                            </p>
                        </div>
                        <div className="col col-sm-2">
                            <div className={`${styles.group_5_copy}`}>
                              <img className={`${styles.rectangle_15_2}`} src="images/rectangle_green.png" alt=""/>
                              <img className={`${styles.ellipse_5_2}`} src="images/ellipse_5_2.png" alt=""/>
                            </div>
                        </div>
                      </div>
                    </div>

                    <div className="col col-sm-3">
                      <div className="row">
                        <div className="col col-sm-2">
                            <div className={`${styles.group_5}`}>
                              <img className={`${styles.ellipse_5}`} src="images/ellipse_5.png" alt=""/>
                              <img className={`${styles.rectangle_15}`} src="images/rectangle_purple.png" alt=""/>
                            </div>
                        </div>
                        <div className="col col-sm-8">
                        <p className={`${styles.price_title}`}>
                            <div>АБОНЕМЕНТЫ </div>
                            </p>
                            <div className={`${styles.fw100}`}>длительность занятий</div>
                            <div className={`${styles.fw100}`}>1,5 час</div>
                            <br/>
                            <p className={`${styles.price_text_1}`}>
                            <div className={`${styles.fw100}`}>4 занятия</div>
                            <div className={`${styles.price_price}`}>4500руб.</div>
                            <div class="text-style-5">&nbsp;</div>
                            <div className={`${styles.fw100}`}>8 занятий</div>
                            <div className={`${styles.price_price}`}>7200 руб.</div>
                            <br/>
                            <div className={`${styles.price_comments}`}>срок действия абонемента <b>30 дней</b></div>
                            <div className={`${styles.price_comments}`}>на выбор <b>1 направление</b></div>
                            <div class="text-style-5">&nbsp;</div>
                            </p>
                        </div>
                        <div className="col col-sm-2">
                            <div className={`${styles.group_5_copy}`}>
                              <img className={`${styles.rectangle_15_2}`} src="images/rectangle_purple.png" alt=""/>
                              <img className={`${styles.ellipse_5_2}`} src="images/ellipse_5_2.png" alt=""/>
                            </div>
                        </div>
                      </div>
                    </div>

                    <div className="col col-sm-3">
                      <div className="row">
                        <div className="col col-sm-2">
                            <div className={`${styles.group_5}`}>
                              <img className={`${styles.ellipse_5}`} src="images/ellipse_5.png" alt=""/>
                              <img className={`${styles.rectangle_15}`} src="images/rectangle_red.png" alt=""/>
                            </div>
                        </div>
                        <div className="col col-sm-8">
                            <p className={`${styles.price_title}`}>
                            <div>БЕЗЛИМИТНЫЙ</div>
                            <div>АБОНЕМЕНТ</div>
                            </p>
                            <p className={`${styles.price_text_1}`}>
                            <br/>
                            <div className={`${styles.fw100} ${styles.price_title}`}>V.I.P.</div>
                            <br/>
                            <div className={`${styles.price_vip_2} `}>11000 руб.</div>
                            <br/>
                            <div className={`${styles.price_comments}`}><b>можно посещать любые занятия в центре</b></div>
                            <br/>
                            <div className={`${styles.price_comments}`}>срок действия абонемента <b>35 дней</b></div>
                            </p>
                        </div>
                        <div className="col col-sm-2">
                            <div className={`${styles.group_5_copy}`}>
                              <img className={`${styles.rectangle_15_2}`} src="images/rectangle_red.png" alt=""/>
                              <img className={`${styles.ellipse_5_2}`} src="images/ellipse_5_2.png" alt=""/>
                            </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Мобильная версия  */}
                <div className={`row d-block d-lg-none`}>
                <Carousel autodivlay>
                    <div className="col col-sm-3">
                      <div className="row">
                          <div className="col col-sm-8">
                              <div className={`${styles.price_title}`}>
                              <div>ПРОБНЫЕ/</div>
                              <div>РАЗОВЫЕ</div>
                              <div>ЗАНЯТИЯ</div></div> 
                              <div className={`${styles.price_text_1}`}>
                              <div className={`${styles.fw100}`}>Пробное</div>
                              <div className={`${styles.fw100}`}>занятие</div>
                              <div className={`${styles.price_price}`}>60 мин. - 350 руб.</div>
                              <div class="text-style-5">&nbsp;</div>
                              <div className={`${styles.fw100}`}>Пробное</div>
                              <div className={`${styles.fw100}`}>занятие</div>
                              <div className={`${styles.price_price}`}>90-120 мин. - 450 руб.</div>
                              <div class="text-style-5">&nbsp;</div>
                              <div className={`${styles.fw100}`}>Разовое</div>
                              <div className={`${styles.fw100}`}>занятие</div>
                              <div className={`${styles.price_price}`}>60 мин. - 900 руб.</div>
                              <div class="text-style-5">&nbsp;</div>
                              <div className={`${styles.fw100}`}>Разовое</div>
                              <div className={`${styles.fw100}`}>занятие</div>
                              <div className={`${styles.price_price}`}>90 мин. - 1350 руб.</div>
                              </div>
                          </div>
                      </div>
                    </div>
                    <div>
                    <div className="col col-sm-3">
                      <div className="row">
                        <div className="col col-sm-8">
                            <p className={`${styles.price_title}`}>
                            <div>АБОНЕМЕНТЫ </div>
                            </p>
                            <div className={`${styles.fw100}`}>длительность занятий</div>
                            <div className={`${styles.fw100}`}>1 час</div>
                            <br/>
                            <p className={`${styles.price_text_1}`}>
                            <div className={`${styles.fw100}`}>4 занятия</div>
                            <div className={`${styles.price_price}`}>3200 руб.</div>
                            <div class="text-style-5">&nbsp;</div>
                            <div className={`${styles.fw100}`}>8 занятий</div>
                            <div className={`${styles.price_price}`}>4900 руб.</div>
                            <div className={`${styles.price_comments}`}>срок действия абонемента <b>30 дней</b></div>
                            <div className={`${styles.price_comments}`}>на выбор <b>1 направление</b></div>
                            <div class="text-style-5">&nbsp;</div>
                            <div className={`${styles.fw100}`}>12 занятий</div>
                            <div className={`${styles.price_price}`}>6900 руб.</div>
                            <br/>
                            <div className={`${styles.price_comments}`}>срок действия абонемента <b>60 дней</b></div>
                            <div className={`${styles.price_comments}`}>на выбор <b>2 направление</b></div>
                            </p>
                        </div>
                      </div>
                    </div>
                    </div>
                    <div>
                    <div className="col col-sm-3">
                      <div className="row">
                        <div className="col col-sm-8">
                            <p className={`${styles.price_title}`}>
                            <div>АБОНЕМЕНТЫ </div>
                            </p>
                            <div className={`${styles.fw100}`}>длительность занятий</div>
                            <div className={`${styles.fw100}`}>1,5 час</div>
                            <br/>
                            <p className={`${styles.price_text_1}`}>
                            <div className={`${styles.fw100}`}>4 занятия</div>
                            <div className={`${styles.price_price}`}>4500руб.</div>
                            <div class="text-style-5">&nbsp;</div>
                            <div className={`${styles.fw100}`}>8 занятий</div>
                            <div className={`${styles.price_price}`}>7200 руб.</div>
                            <br/>
                            <div className={`${styles.price_comments}`}>срок действия абонемента <b>30 дней</b></div>
                            <div className={`${styles.price_comments}`}>на выбор <b>1 направление</b></div>
                            <div class="text-style-5">&nbsp;</div>
                            </p>
                        </div>
                      </div>
                    </div>
                    </div>
                    <div>
                    <div className="col col-sm-3">
                      <div className="row">
                        <div className="col col-sm-8">
                            <p className={`${styles.price_title}`}>
                            <div>БЕЗЛИМИТНЫЙ</div>
                            <div>АБОНЕМЕНТ</div>
                            </p>
                            <p className={`${styles.price_text_1}`}>
                            <br/>
                            <div className={`${styles.fw100} ${styles.price_title}`}>V.I.P.</div>
                            <br/>
                            <div className={`${styles.price_vip_2} `}>11000 руб.</div>
                            <br/>
                            <div className={`${styles.price_comments}`}><b>можно посещать любые занятия в центре</b></div>
                            <br/>
                            <div className={`${styles.price_comments}`}>срок действия абонемента <b>35 дней</b></div>
                            </p>
                        </div>
                      </div>
                    </div>
                    </div>
                  </Carousel>
                </div>
              </div>   
            </div>
            
            <div ref={hallsRef} className={styles.l_unconstrained}>
                <div className="row justify-content-center">
                  <div className={`${styles.underline_margin_check} ${styles.title}`}>Залы</div>
                </div>
                <div className="row justify-content-center">
                  <div className={`${styles.hall_branch_button} ${isBranchLifeActive ? styles.hall_branch_active : ""}`} id='Life' onClick={handleHallsBranchClick}>Life | ЖК Окла</div>
                  <div className={`${styles.hall_branch_button} ${isBranchImpulseActive ? styles.hall_branch_active : ""}`} id='Impulse' onClick={handleHallsBranchClick}>Impulse | ТРЦ Leo Mall</div>
                  <div className={`${styles.hall_branch_button} ${isBranchEnergyActive ? styles.hall_branch_active : ""}`} id='Energy' onClick={handleHallsBranchClick}>Energy | ТРЦ Променад</div>
                </div>
                <div className="row no-gutters" >
                      {isBranchImpulseActive && <Carousel autoPlay>
                      <div class="row">
                        <div class={`col-md-6 ${styles.halls}`}>
                          <img className={styles.hall_images} src="images/halls/leo-big.jpg" alt=""/>
                        </div>
                        <div class="col-md-6">
                          <div className="row justify-content-left">
                              <div className={styles.hall_description}>
                              <div class={styles.halls_title}>Зал PRO</div>
                              <span class={styles.halls_size_details}>120 м</span>
                              <span class={styles.halls_size_details}><sup>2</sup></span><sup></sup>
                              <span class={styles.halls_size_details}>|</span>
                              <span class={styles.halls_price}>От 1000 р/час</span>
                              <br/>
                              <br/><strong class={styles.halls_size_details_18}>ПОКРЫТИЕ: </strong>
                              <span class={styles.halls_size_details_20}>ЛАМИНАТ</span> 
                              <br/>
                              <strong class={styles.halls_size_details_18}>ЗВУК: </strong>
                              <span class={styles.halls_size_details_20}>профессиональная звуковая система JBL EON</span>
                              <br/>
                              <span class={styles.halls_size_details_20}>
                                (колонки 2 шт., диджейский пульт, подключение через mini-jack)
                              </span>
                              <br/>
                              <strong class={styles.halls_size_details_18}>ОБОРУДОВАНИЕ: </strong>
                                <span class={styles.halls_size_details_20}>
                                  коврики, балетные станки, неоновая подсветка зеркал
                                </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class={`col-md-6 ${styles.halls}`}>
                          <img className={styles.hall_images} src="images/halls/leo-medium.jpg" alt=""/>
                        </div>
                        <div class="col-md-6">
                          <div className="row justify-content-left">
                              <div className={styles.hall_description}>
                              <div class={styles.halls_title}>Зал Base</div>
                              <span class={styles.halls_size_details}>90 м</span>
                              <span class={styles.halls_size_details}><sup>2</sup></span><sup></sup>
                              <span class={styles.halls_size_details}>|</span>
                              <span class={styles.halls_price}>От 800 р/час</span>
                              <br/>
                              <br/><strong class={styles.halls_size_details_18}>ПОКРЫТИЕ: </strong>
                              <span class={styles.halls_size_details_20}>ЛАМИНАТ</span> 
                              <br/>
                              <strong class={styles.halls_size_details_18}>ЗВУК: </strong>
                              <span class={styles.halls_size_details_20}>профессиональная звуковая система JBL EON</span>
                              <br/>
                              <span class={styles.halls_size_details_20}>
                                (колонки 2 шт., диджейский пульт, подключение через mini-jack)
                              </span>
                              <br/>
                              <strong class={styles.halls_size_details_18}>ОБОРУДОВАНИЕ: </strong>
                                <span class={styles.halls_size_details_20}>
                                  коврики, балетные станки, неоновая подсветка зеркал
                                </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Carousel>
                    }
                    {isBranchLifeActive && <div class={styles.halls_size_details_18}><br/>Мы работаем над фотографиями. Скоро эта секция будет готова.</div>}
                    {isBranchEnergyActive && <div class={styles.halls_size_details_18}><br/>Мы работаем над фотографиями. Скоро эта секция будет готова.</div>}
                </div>
            </div>


          <div ref={feedbackRef} className={styles.l_unconstrained}>
            <div className="row justify-content-center">
              <div className={`${styles.underline_margin_check} ${styles.title}`}>ОТЗЫВЫ</div>
            </div>
            <div className={`${styles.row} ${styles.otzyvy}`}>
              <div className="col">
                {/* <div className="dots group">
                  <div className="ellipse-505"></div>
                  <div className="ellipse-505-copy"></div>
                  <div className="ellipse-505-copy-2"></div>
                  <div className="ellipse-505-copy-3"></div>
                </div> */}
                {/* <img className="otzyvy-2" src="images/otzyvy.png" alt="ОТЗЫВЫ"  title="ОТЗЫВЫ"/> */}
                <p className={styles.text_otzyvy}>"Я всегда мечтала научиться танцевать, но думала, что уже слишком поздно. Однако, когда я начала заниматься в DEmarche, моя жизнь изменилась. Я начала чувствовать себя увереннее и сильнее, а мои движения стали более грациозными и энергичными. Преподаватели школы так талантливы и вдохновляющи, что каждый урок был настоящим праздником! Я благодарна Demarche за то, что они помогли мне осуществить мою мечту и стать лучшей версией себя!<br/><span className="text-style-8">&nbsp;</span><br/><span className="text-style-8">&nbsp;</span><br/><span className="text-style-8">&nbsp;</span><br/><span className="text-style-8">&nbsp;</span><br/><span className="colorffffff">Марина Петрова, 22 марта 2023</span><br/><span className="colorffffff">отзыв оставлен на сайте </span><a  className="text-style-9">2gis</a></p>
                {/* <div className="group-7">
                  <div className="rectangle-18"></div>
                  <p className="text-67">”</p>
                </div> */}
                {/* <div className="rectangle-4-copy-2"></div> */}
                {/* <img className="layer-6-copy-6" src="images/layer_6_copy_6.png" alt=""/> */}
              </div>
            </div>
          </div>

          <div ref={contactRef} className={styles.l_unconstrained}>
            <div className="row justify-content-center">
              <div className={`${styles.underline_margin_check} ${styles.title}`}>Контакты</div>
            </div>
            <div className={`row ${styles.about_us} justify-content-center`}>
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <div className={styles.contacts}>
                      <img className={styles.phone_call} src="images/free-icon-phone-call-4041.png" alt="" width="20"/>
                      <div className={styles.contacts_text}><a href="callto:89219062746">+7 (921) 906-27-46</a></div>
                    </div>
                    <div className={styles.contacts}>
                      <img className={styles.empty_email} src="images/free-icon-empty-email-805.png" alt="" width="20"/>
                      <div className={styles.contacts_text}><a href="mailto:demarche_tsentr@mail.ru">demarche_tsentr@mail.ru</a></div>
                  </div>
                  <p className={styles.about_us_text}>Подписывайтесь на наши соц. сети, чтобы быть в курсе актуальных новостей, событий, акций и анонсов центра танцев Demache!</p>
                    <div className={styles.social_media_icons}>
                      <div className={`${styles.icon} ${styles.group}`}>
                        <a href="https://www.instagram.com/demarche.centre"><img src="images/instagram.png"  width="35"/></a>
                        <a href="https://vk.com/demarche_dance"><img src="images/vk.png" alt="" width="35"/></a>
                        <a href="https://t.me/@DM-centre"><img src="images/telegram.png"  width="35"/></a>
                        <a href="https://t.me/@DM-centre"><img src="images/whatsapp.png" alt="" width="35"/></a>
                      </div>
                    </div>
                </div>
               
              </div>
              <div className="row justify-content-center">
               <div className="col-md-4">
                    <div className="contacts">
                      <img className={styles.icon_location} src="images/free-icon-location-974217.png" alt="" width="20"/>
                      <div className={styles.contacts_text}><a href="https://yandex.ru/maps/org/demarche/133912504389/?indoorLevel=4&ll=30.225291%2C60.022221&z=16">Impulse | LeoMall, Планерная ул., 59,<br/>Санкт-Петербург, 197373, 4 этаж</a></div>
                    </div>
                    <div>
                      <img className={styles.map} src="images/map.png" alt=""/>
                  </div>
                </div>
                <div className="col-md-4">
                    <div className="contacts">
                      <img className={styles.icon_location} src="images/free-icon-location-974217.png" alt="" width="20"/>
                      <div className={styles.contacts_text}><a href="https://yandex.ru/maps/-/CDgNnNow">Energy | Комендантский проспект, 9к2<br/>Санкт-Петербург, 197227, 2 этаж</a></div>
                    </div>
                    <div>
                      <img className={styles.map} src="images/map-promenad.png" alt=""/>
                  </div>
                </div>
                <div className="col-md-4">
                    <div className="contacts">
                      <img className={styles.icon_location} src="images/free-icon-location-974217.png" alt="" width="20"/>
                      <div className={styles.contacts_text}><a href="https://yandex.ru/maps/-/CDgNnQ~k">Life | Жилой комплекс Окла, Суздальское ш., 20<br/>Санкт-Петербург, 1 этаж</a></div>
                    </div>
                    <div>
                      <img className={styles.map} src="images/map-okla.png" alt=""/>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-md-6">
                <img className={styles.map} src="images/map.png" alt=""/>
            </div> */}
          </div>

        </div>

  );
}

export default AdultsPage;