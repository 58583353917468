const styles = [];

styles.push({name: "JAZZ-FUNK", 
             desciption: "Популярный стиль танца, который притягивает взгляд и завораживает. Крайне эмоциональный, импульсивный, и часто эпатажный. Он вобрал в себя огромное количество стилей – от ультрасовременных vogue, locking, king tut до классики и балета. Jazz-funk позволяет танцовщику раскрыться и показать свою универсальность.",
            videoUrl:""});

styles.push({name: "CONTEMPORARY", 
           desciption: "Популярное танцевальное направление, основанное на технике джаз-модерна, классического балета, восточных практик цигуна, йоги и пилатеса. Танец, который напоминает душевный разговор с самим собой. Чувственный, эмоциональный, пластичный, эффектный. Здесь крайне важна связь между внутренним состоянием танцовщика и его воплощением в танце.",
          videoUrl:"/videos/adults/styles/contemp.mov"});

styles.push({name: "DANCEHALL", 
          desciption: "Серьезный уличный танец с ямайским темпераментом. По степени физической нагрузки максимально приближен к интенсивной тренировке в спортзале. Подходит для любого возраста и физической подготовки. Заряжает позитивной энергией, влюбляет в себя с первых занятий и заслуженно входит в топ современной хореографии.",
         videoUrl:"dancehall.MP4"});

styles.push({name: "HIGH HEELS", 
          desciption: "Пластичная женственная хореография с чувственным подтекстом. Философия данного танцевального стиля – красота, женственность, раскрепощение в движениях. Танец для удовольствия и соблазнения. Идеально подходит для девушек любого возраста, желающих поднять самооценку, прокачать свое женское начало и привлекательность.",
         videoUrl:""});

styles.push({name: "GIRLY HIP-HOP", 
         desciption: "Пограничный стиль между такими популярными направлениями, как hip-hop и jazz funk Грув, базовые движения, амплитуда и, конечно, музыка в этом стиле — от первого. И пластичность, манерность, резкость и яркая подача от второго.",
        videoUrl:""});

styles.push({name: "AFRO", 
        desciption: "Стиль танца, который основан на технике движений различных ритуальных африканских танцев. Раскрепощенность движений, четкое следование ритмам музыки, яркие жесты и артикуляция — характерные черты, отражающие Афро танцы.",
       videoUrl:""});

export default styles;