import './Common.css';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './Components/HomePage/HomePage';
import ChildrenPage from './Components/Children/ChildrenPage';
import AdultsPage from './Components/Adults/AdultsPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/children" element={<ChildrenPage />} />
        <Route path="/adults" element={<AdultsPage />} />
      </Routes>
    </Router>
  );
}

export default App;
