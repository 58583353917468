const coaches = [];

coaches.push(
        {
                name: "Мария", 
                surname: "Бычкова",
                style: "Parents Crew",
                photo: "images/bychkova.png",
                videoUrl:"videos/coaches/bychkova.mp4",
                description: `• Тренер команд чемпионов России
                • Двукратная Чемпионка России по версии ОРТО — Dance Show
                • Более 10-ти лет опыта работы с детьми от 3х лет
                • Более 11-ти лет опыта режиссёром-постановщиком и хореографом
                • Финалистка Чемпионата Мира «World Olympiad Dance Show»
                • На протяжении пяти лет режиссер-постановщик и хореограф проектов «Мисс Политех» и «Мистер Политех»
                • Режиссёр спектаклей и мюзиклов «Музыка небес», «Музыка небес 2: иная грань реальности», «Последний час декабря», «С Днём Святого Валентина или все вон!»`
        },
        {
                name: "Алессандро", 
                surname: "Джаз",
                style: "Afro",
                photo: "images/jazz.png",
                videoUrl:"videos/coaches/jazz.mp4",
                description: `• Гуру стиля Afro прибывший из Бразилии Яркий представитель направлений Hip-Hop, Dancehall и House с танцевальным опытом 30 лет! 
                • Хореограф-постановщик артистов (Мот, Егор Крид, Натан)
                • Хореограф NIKE RUSSIA. 
                • Lil’Jazz посетил со своими мастер-классами более 60-ти стран мира!`
        },
        {
                name: "Анна", 
                surname: "Забирова",
                style: "Dancehall",
                photo: "images/zabirova.png",
                videoUrl:"videos/coaches/zabirova.mov",
                description: `• Призер хореографического чемпионата «Stay Lit» в номинации «solo profi», 2022
                • Многократный призер баттлов по «Dancehall» с 2021 года в городах России (г.Москва, г.Санкт-Петербург, г.Вологда, г.Тюмень, г.Екатеринбург, г.Новосибирск, г.Челябинск, г.Пермь)`
        },
         {
                name: "Глеб", 
                surname: "Доценко",
                style: "Breaking",
                photo: "images/docenko.png",
                videoUrl:"videos/coaches/docenko.mov",
                description: `• Опыт преподавания и тренерской работы более 10 лет." +
                • Посещал мастер-классы брейкинг-танцовщиков мирового уровня, являюсь одним из топовыхтанцоров стран СНГ и регулярно провожу мастер-классы по всей России
                
                • «RedbullBCONE Russia cypher 2021
                • 1 место в номинации Top Rock
                • 1 место в номинации Footwork
                OutBreak Europe 2013
                • 1 место в номинации Rip you country
                Yalta Summer Jam 2021-2023 Ежегодный международный фестиваль хип-хоп культуры 
                • 1 место в номинации Сила и Мощь
                • 1 место в номинации Footwork
                • 1 место в номинации Top Rock & Footwork
                ENERGY DANCE EVENT 2023
                • 1 место в номинации Solo  
                V1 Battle 2021-2023 Международный хип-хоп фестиваль
                • 1 место в номинации 2x2`
        },
        {
                name: "Екатерина", 
                surname: "Красноперова",
                style: "Girly Hip-Hop",
                photo: "images/krasnoperova.png",
                videoUrl:"videos/coaches/krasnoperova.mov",
                description: `• Полуфиналистка TV-шоу «Новые танцы» в составе команды «I LIKE THAT»
                • Участница команды «I Like that» (Санкт-Петербург)
                • Ex-участница «Be yourself» (Москва)
                • Работала с артистами: Тату, Нонна Гришаева, Антон Макарский и др.
                • Руководитель teens команд «Mels», «Reflection», которые занимают призовые места на чемпионатах СПб
                • Выступления с командой на мероприятиях  крупных брендов, одежда BOSCO , Нефтяные компании (Газпром. , Рос.нефть, Норникель и др) , ювелирные компании , транспортные и др.`
        },
        {
                name: "Екатерина", 
                surname: "Косымбарова",
                style: "Hip-Hop",
                photo: "images/kosymbarova.png",
                videoUrl:"videos/coaches/kosymbarova.mp4",
                description: `• участница проекта «НОВЫЕ ТАНЦЫ» на ТНТ (1,2 сезон)
                • чемпионка World of Dance Hong Kong 2024
                • многократная чемпионка России по версиям Hip Hop Unite, United Dance Open
                • серебряный призер чемпионата мира United Dance Open 2017
                • чемпионка Европы United Dance Open 2019
                • серебряный призер чемпионата России United Dance Open в номинации Waacking 1x1 2021
                • КМС по фитнес-аэробике`
        },
        {
                name: "Эвелина", 
                surname: "Гасымова",
                style: "Krump",
                photo: "images/gasymova.png",
                videoUrl:"videos/coaches/gasymova.mp4",
                description: `• 2021 год приглашенный участник концерта « Танцы последний сезон» на тнт
                • 2018 год 2 место чемпионат мира по крампу FCD Дюссельдорф Германия
                • 2019 год топ 8 чемпионат мира по крампу KOB Япония
                • 2017 год топ 8 чемпионат мира по крампу FCD Дюссельдорф Германия
                • 2018 год 2 место чемпионат мира по крампу ILLEST Париж Франция
                • 2018 год 1 место чемпионат России по крампу ILLEST
                • 2023 год 1 место, Казахстан AKS отбор в Париж на ILLEST`
        },
        {
                name: "Ксения", 
                surname: "Куликова",
                style: "Hip-Hop",
                photo: "images/kulikova.png",
                videoUrl:"videos/coaches/kulikova.mov",
                description: `• Участник таких крупных чемпионатов как: HHI Russia и HHU в Москве, HHI World в Аризоне, World of Dance в Сан-Франциско и тд
                • Участник таких телевизионных проектов как: «Dance Revolution» на первом канале, «НОВЫЕ ТАНЦЫ» на канале ТНТ
                • Мастер спорта по фитнес-аэробике`
        },
        {
                name: "Михаил", 
                surname: "Евграфов",
                style: "Contemporary",
                photo: "images/evgrafov.png",
                videoUrl:"videos/coaches/evgrafov.mp4",
                description: `• Выпускник Академии Русского балета им. А. Я. Вагановой
                • Стажировался в г. Париж (Труппа Каролин Карлосон) 
                • Г. Бостон (Boston ballet) 
                • Участник телепроектов «Танцуют все», «Танцы на ТНТ»
                • Член жюри международных танцевальных конкурсов, педагог, хореограф`
        },
        {
                name: "Елена", 
                surname: "Шибалова",
                style: "Krump",
                photo: "images/shibalova.png",
                videoUrl:"videos/coaches/shibalova.mp4",
                description: `• В 2019 и 2020 года получила дважды премию FCD Krump Awards в номинации Best Female Dancer среди девочек по всему миру.
                • В 2019 года стала частью одного из фемели основателя стиля Tight Eyez - NY MADNESS.
                • Обладательница премии Best Female Dancer 2015 и 2016 по версии Krump1love и RSK на территории СНГ. 
                • 2-x кратная чемпионка мира - EBS 2016 & ILLEST 2017 в категории girls 1x1. 
                • Победительница таких фестивалей как Clash of Generations 2021,2022,2023, Krumpire Main Battle 2017 и 2020, Kill The Stage Tournament 2017 и 2018, 8 bucks The Krumpire 2016 & 2018 & 2019, SDK Europe 2016, Illest Russia 2016, BOE 2016, Respect my talent 2015, 7toSmoke The Krumpire 2015, Krump Bulls 2014, First Ghetto Arena и тд.
                • Давала классы в следующих странах:
                Germany, Latvia, Estonia, Russia, Kazakhstan, Uzbekistan, Ukraine, Philippines, United Kingdom, Japan, India, Switzerland, France, Spain, Canada`
        },
        {
                name: "Арчын", 
                surname: "Кармандаев",
                style: "Wacking",
                photo: "images/karmandaev.png",
                videoUrl:"videos/coaches/karmandaev.mp4",
                description: `• 2013 - Solo advanced (UDO) – winner
                • 2013 - Locking up waacking - final
                • 2013 - Street Star Stokholm Sweeden. top 6
                • 2014 - Solo advanced (UDO) – winner
                • 2014 - Solo advanced (HHI) –final
                • 2014 - Solo All Styles (Sums Battle) – winner
                • 2014 - Solo waacking (Electro Soul Satisfaction) USA Wash.DC - winner
                • 2014 - Step ya game up 2013/2014 NYC top 8
                • 2015 - UDO SPB - winner
                • 2015 - M Battle all styles - winner
                • 2015 - Smack'n'whack - final
                • 2016 - SDK Europe Чехия top 8
                • 2016 - Smack'n"Whak - winner
                • 2016 - Baltic Session (Tallinn, Estonia) - winner
                • 2016 - Battle "M" - winner
                • 2017 - WaackerLand - Final
                • 2017 - Back to the style. Pisa. Italy top 8
                • 2017 - Smack'n'whack, prelims to HotMess (Montreal, CA.) Winner.
                • 2018 - Red Bull BC ONE Camp waacking+house 2vs2 winner
                • 2019 - Red Bull BC ONE all styles final vs. Lil Kes.
                • 2020 - Electro vs. Vogue vs. All styles. Saint-Petersburg. Winner
                • 2023 - Waacking Only - Final. Moscow
                • 2023 - Vsem waacking - Final. SPB`
        },
        {
                name: "Никита", 
                surname: "Цеплухин",
                style: "Locking",
                photo: "images/tsepluhin.png",
                videoUrl:"videos/coaches/tcepluhin.mov",
                description: `• Участник одной из самых ярких и узнаваемых фанк команд - ‘That’s the point’. Основной хореограф команд ‘MAD’s’ & ‘Pennywise crew’. Участник skillhouse community.
                • Generation step battle(2021) - final
                • M battle battle (locking) (2021) - final
                • Games of funk battle (2021) - 3 place
                • Games of funk battle (2023) - 2 place
                • Games of funk battle (2024) - final
                • Top talent tournament battle (2019)- 2 place
                • Locking day (2018)- 2 place
                • Участник locking league Saint-Petersburg 
                •  Участник команды that’s the point
                В составе команды:
                • Start Dance vol.17 - 1st place
                • Street Summit - 2nd place 
                • UDO XXVI - 2nd place 
                • Будь лучше - 2nd place 
                • МЕГАБИТВА - 3d place`
        },
        {
                name: "Мария", 
                surname: "Трескова",
                style: "Popping",
                photo: "images/treskova.png",
                videoUrl:"videos/coaches/tresskova.mp4",
                description: `• "Buck and Pop", 2017/ Popping, 1 место
                • "Battle 0, 33", 2017/ All styles, 2 место 
                • "Home battle", 2018/ Popping, 1 место 
                • "Battle 0,33", 2018/ Popping, 1 место 
                • "Home battle", 2018/All styles, 1 место
                • «Танцевальный квартирник vol.5”, 2018/Showcase, winner
                • «No limit dance battle Moscow”, 2019/All styles beg, 1 место
                • "Tarantino Vogue Night",2019/ Me Against The Music, 1 место
                • "We are the Poppers", 2021/ Crimea, Waving Jam Winner
                • SuperБитва, 2023/Ярославль, All styles Adults 1 место
                • Afro Kvartirnik, 2023/СПб All styles 2x2 Winner
                • MBattle, 2023/ Popping Finalist
                • Feminine Battle, 2023/ All styles Winner
                • BootySphere Battle, 2023/ All styles Finalist
                • Freestyler Battle, 2024/ Freestyle Finalist
                • Piter in da Heels, 2024/ All styles Semi-Finalist
                • IDOL Dance Championship, 2024/ Solo PRO 1st place
                
                • Финалистка Instagram конкурса от Елены Темниковой "Т4Танцы"
                • Участница танцевального проекта «ЛИГА» Winline, VK video
                
                • Ледовый дворец/танцор
                реклама магазина Z37 New Balance/танцор
                • VK FEST 20-21.7.18/Sony Extra Bass/танцор 
                • PlayArt Show/ танцор
                муз.клип Woodju - Dance like no one is watching/танцор
                • Рекламный ролик для сети ресторанов FRANK /танцор
                • Рекламный ролик для Wolkswagen Polo/танцор`
        },
        {
                name: "Екатерина", 
                surname: "Калинина",
                style: "Baby Dance",
                photo: "images/kalinina.png",
                videoUrl:"",
                description: `• Педагог по эстрадным и классическим видам танца
                • Призер международных соревнований, лауреат 1 степени ,в том числе Гран-При Всероссийских фестивалей по современной хореографии`
        },
        {
                name: "Дарья", 
                surname: "Курмелева",
                style: "Baby Dance",
                photo: "images/kurmeleva.png",
                videoUrl:"",
                description: `• Окончила Санкт Петербургский государственный институт культуры , кафедра хореографии(Руководство хореографическим любительским коллективом)
                • Прошла профессиональную переподготовку в СПбГИК по программе «Педагогическая деятельность хореографа в учреждениях культуры и образования»
                • Участница Russia Hip Hop Dance Championship 2022 в номинации Adult Show
                • Профессиональное онлайн обучение с выдачей диплома переподготовки «Богатый фитнес-тренер» Екатерины Акулиной
                • Авторский онлайн-курс Татьяны Тарабоновой по contemporary dance
                • Авторский курс по  high heels
                всемирно известного педагога-хореографа FRAULES(Елены Яткиной)`
        },
        {
                name: "Александра", 
                surname: "Ярошко",
                style: "Baby Dance",
                photo: "images/yaroshko.png",
                videoUrl:"",
                description: `• «Мастер спорта России» по спортивной аэробике.
                • Многократная чемпионка первенств СЗФО, России.
                • Чемпионка Мира ( Мексика 2014г.)
                • Являлась членом молодёжной команды России по спортивной аэробике . 
                • Артистка шоу в разных странах . 
                • Артистка шоу в СПб « royal birds , Я не такая»`
        },
        

);



export default coaches;