const styles = [];

styles.push({name: "ЭСТРАДНЫЙ ТАНЕЦ", 
             desciption: "Синтез различных танцевальных стилей , всегда яркий, зажигательный, эмоциональный. Он предназначен для исполнений на эстраде, преимущественно на концертах. В каждом хореографическом номере данного направления должна четко прослеживаться драматургия.",
             videoUrl:"videos/children/styles/Estrada2.mov"});

styles.push({name: "JAZZ-FUNK", 
             desciption: "Популярный стиль танца, который притягивает взгляд и завораживает. Крайне эмоциональный, импульсивный, и часто эпатажный. Он вобрал в себя огромное количество стилей – от ультрасовременных vogue, locking, king tut до классики и балета. Jazz-funk позволяет танцовщику раскрыться и показать свою универсальность.",
            videoUrl:"videos/children/styles/Jazz-Funk.mov"});

styles.push({name: "BABY DANCE", 
            desciption: "Танцевальное направление для малышей от 2 до 3 лет,которое дает базовые навыки и помогает определиться с дальнейшим танцевальным направлением в будущем.",
           videoUrl:"videos/children/styles/BabyDance.mov"});

styles.push({name: "DANCESHOW", 
           desciption: "Танцевальная композиция, построенная по основным законам драматургии*, имеющая четко выстроенный сюжет либо не имеющая сюжета, в которой используются следующие стили и танцевальные техники: джаз, модерн-джаз, контемпорари, классика, свободная пластика. Их можно исполнять как в чистой форме, так и в сочетании двух и более стилей.",
          videoUrl:"videos/children/styles/Danceshow.mov"});

styles.push({name: "HIP-HOP", 
          desciption: "Не просто танцевальный стиль, а целая субкультура, охватывающая свою манеру одеваться, говорить, вести себя. Танец включает множество направлений: Breakdance, Locking, Popping. На первый взгляд выглядит простым, но в реале, чтобы научиться круто танцевать хип-хоп, понадобятся интенсивные тренировки. Нагрузка приходится на все группы мышц, поэтому занятие по хип-хопу заменит полноценную фитнес-тренировку.",
         videoUrl:"videos/children/styles/Hip-Hop.mov"});

styles.push({name: "GIRLY HIP-HOP", 
         desciption: "Пограничный стиль между такими популярными направлениями, как hip-hop и jazz funk Грув, базовые движения, амплитуда и, конечно, музыка в этом стиле — от первого. И пластичность, манерность, резкость и яркая подача от второго.",
        videoUrl:"videos/children/styles/girly.mov"});

styles.push({name: "DANCEHALL", 
          desciption: "Серьезный уличный танец с ямайским темпераментом. По степени физической нагрузки максимально приближен к интенсивной тренировке в спортзале. Подходит для любого возраста и физической подготовки. Заряжает позитивной энергией, влюбляет в себя с первых занятий и заслуженно входит в топ современной хореографии.",
         videoUrl:"videos/children/styles/Dancehall kids.mov"});

styles.push({name: "VOGUE", 
          desciption: "Брат Вакинга - стиль, базирующийся на модельных позах и подиумной походке. Отличительные особенности: быстрая техника движения руками, вычурная манерная походка, падения, вращения, обильное количество позировок, эмоциональная игра.",
         videoUrl:"videos/children/styles/Vogue.mov"});

styles.push({name: "CONTEMPORARY", 
          desciption: "Популярное танцевальное направление, основанное на технике джаз-модерна, классического балета, восточных практик цигуна, йоги и пилатеса. Танец, который напоминает душевный разговор с самим собой. Чувственный, эмоциональный, пластичный, эффектный. Здесь крайне важна связь между внутренним состоянием танцовщика и его воплощением в танце.",
         videoUrl:"videos/children/styles/Contemporary.mp4"});

styles.push({name: "BREAKING", 
          desciption: "Представляет танцевальное направление хип-хоп культуры и включает в себя множество стилей. Для его исполнения необходимы хорошая координация, физическая выносливость, чувство ритма и гибкость.",
         videoUrl:"videos/children/styles/Breaking.mov"});

styles.push({name: "LOCKING", 
          desciption: "Один из самых позитивных фанковых стилей танца, характеризующийся шуточной и даже комичной манерой исполнения. Локинг характеризуется наличием базовых движений, четко закрепленных традицией и исполняющихся всеми танцорами строго единообразно. Это быстрые «запирающие» (locking) и «указывающие» (pointing) движения, наряду с хлопками руками (slaps, claps, giving five) и шпагатами (splits).",
         videoUrl:"videos/children/styles/Locking.mov"});

styles.push({name: "WAACKING", 
          desciption: "Отличительной особенностью этого стиля танца являются резкие, быстрые и четки движения рук в сочетании с манерными позами и проходками в духе подиумных моделей. Движения танцоров мощные и амплитудные, а также очень чувственные.",
         videoUrl:"videos/children/styles/Danceshow.mov"});

styles.push({name: "POPPING", 
         desciption: "Oригинальный танец не только в названии, но и в технике исполнения. Его суть заключается в выполнении танцорами сокращений мышц, из-за чего наблюдается довольно резкое вздрагивание различных частей тела.",
        videoUrl:"videos/children/styles/Popping.mov"});

styles.push({name: "KRUMP", 
        desciption: "По духу этот танец энергичный и довольно резкий, агрессивный. К тому же Krump очень разнообразный, так как разные люди могут использовать абсолютно отличные друг от друга стили. Главное, чтобы Krump соответствовал характеру танцора и его мироощущению. Krump — это своеобразная альтернатива уличному насилию, которое было очень распространено в Америке в конце XX века.",
       videoUrl:"videos/children/styles/Krump.mov"});

styles.push({name: "AFRO", 
       desciption: "Стиль танца, который основан на технике движений различных ритуальных африканских танцев. Раскрепощенность движений, четкое следование ритмам музыки, яркие жесты и артикуляция — характерные черты, отражающие Афро танцы.",
      videoUrl:"videos/children/styles/Afro.mov"});

export default styles;