
import styles from './youtubeModalComponent.module.css';
import Modal from 'react-bootstrap/Modal';
import ReactPlayer from 'react-player';

const YoutubeModalComponent = ({ url, show, handleClose, name }) => {

  return (
      <Modal className={styles.modal} show={show} onHide={handleClose}>
        <Modal.Header className={styles.modal_header} closeButton>
        <Modal.Title>{name}</Modal.Title>
          </Modal.Header>
        <Modal.Body className={styles.modal_body}>
          {url && <ReactPlayer className={styles.player} playing={true} controls={true} height={"90vh"} width={"unset"} url={url} /> }
          {!url && <h5 className="row justify-content-center">Видео в работе</h5> }
        </Modal.Body>
      </Modal>
  )}

  export default YoutubeModalComponent;