const coaches = [];

coaches.push(
        {
                name: "Алессандро", 
                surname: "Джаз",
                style: "Afro",
                photo: "images/jazz.png",
                videoUrl:"videos/coaches/jazz.mp4",
                description: `• Гуру стиля Afro прибывший из Бразилии Яркий представитель направлений Hip-Hop, Dancehall и House с танцевальным опытом 30 лет! 
                • Хореограф-постановщик артистов (Мот, Егор Крид, Натан)
                • Хореограф NIKE RUSSIA. 
                • Lil’Jazz посетил со своими мастер-классами более 60-ти стран мира!`
        },
        {
                name: "Анна", 
                surname: "Забирова",
                style: "Dancehall",
                photo: "images/zabirova.png",
                videoUrl:"images/zabirova.mov",
                description: `• Призер хореографического чемпионата «Stay Lit» в номинации «solo profi», 2022
                • Многократный призер баттлов по «Dancehall» с 2021 года в городах России (г.Москва, г.Санкт-Петербург, г.Вологда, г.Тюмень, г.Екатеринбург, г.Новосибирск, г.Челябинск, г.Пермь)`
        },
        {
                name: "Михаил", 
                surname: "Евграфов",
                style: "Contemporary",
                photo: "images/evgrafov.png",
                videoUrl:"videos/coaches/evgrafov.mp4",
                description: `• Выпускник Академии Русского балета им. А. Я. Вагановой
                • Стажировался в г. Париж (Труппа Каролин Карлосон) 
                • Г. Бостон (Boston ballet) 
                • Участник телепроектов «Танцуют все», «Танцы на ТНТ»
                • Член жюри международных танцевальных конкурсов, педагог, хореограф`
        },
        {
                name: "Екатерина", 
                surname: "Красноперова",
                style: "Girly Hip-Hop",
                photo: "images/krasnoperova.png",
                videoUrl:"videos/coaches/krasnoperova.mov",
                description: `• Полуфиналистка TV-шоу «Новые танцы» в составе команды «I LIKE THAT»
                • Участница команды «I Like that» (Санкт-Петербург)
                • Ex-участница «Be yourself» (Москва)
                • Работала с артистами: Тату, Нонна Гришаева, Антон Макарский и др.
                • Руководитель teens команд «Mels», «Reflection», которые занимают призовые места на чемпионатах СПб
                • Выступления с командой на мероприятиях  крупных брендов, одежда BOSCO , Нефтяные компании (Газпром. , Рос.нефть, Норникель и др) , ювелирные компании , транспортные и др.`
        },
        {
                name: "Кирилл", 
                surname: "Виноградов",
                style: "Hip-Hop",
                photo: "images/vinogradov.png",
                videoUrl:"videos/coaches/vinogradov.mp4",
                description: `• Танцевальный стаж >13 лет. Преподаю с 2013 года. Участник и победитель множества чемпионатов Сибири, таких как: 
                • 1 место Битва Стилей 3x3 «Dweezh Contest», Улан-Удэ 2020
                • 1 место Hip-hop (duet) «Hip-hop Unite», Иркутск 2019
                • 1 место Hip-hop (profi) «Hip-hop Unite», Иркутск 2019
                • 1 место Hip-hop (profi) «Hip-hop city», Иркутск 201
                • Участники World Of Dance 2017 Красноярск, Sibprokach 2018`
        },

);



export default coaches;