import { useState } from 'react';
import { useMask } from '@react-input/mask';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';

const RequestModelComponent = ({ show, handleClose }) => {

  const [validated, setValidated] = useState(false);
  const [isMobileValid, setIsMobileValid] = useState(true);
  const [isBranchValid, setIsBranchValid] = useState(true);
  const [branch, setBranch] = useState(true);
  const [isSent, setIsSent] = useState(false);

  const modifyMask = (input) => {
    const first = input[0];
    input = input.substring(1);
    
    return { mask: first === '+' ? '__ (___) ___-__-__' : '_ (___) ___-__-__' };
  };

  const mobileRef = useMask({ mask: '_ (___) ___-__-__', modify: modifyMask, replacement: { _: /./ } });

  const handleSubmit = (event) => { 
    event.preventDefault();
    var headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
    const formData =  event.target;
console.log(formData.branch.value);


    if (formData.branch.value == "None")
    {
      setIsBranchValid(false);
      return false;
    }

    if (!formData.phone.value)
    {
      setIsMobileValid(false);
      return false;
    }

    var data = {
        "fullname": formData.name.value,
        "age": formData.age.value,
        // "email": form.form.elements[1].value,
        "mobile": formData.phone.value,
        "branch": formData.branch.value,
        "comments": formData.comments.value
      }

    var formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    console.log("formBody " + formBody)

    fetch('/admin/send-telegram-message', { method: 'POST', headers : headers, body: formBody  } )
    .then(function(response) {
      if(response.ok) {
          setIsSent(true);
          setTimeout(function (){ 
            handleClose();
        }, 2500);
       return false;
      }
      throw new Error('Что-то пошло не так :( ');
    })
    .catch(function(error) {
      console.log(error);
    });

  };

  const mobileChange = (value) => {
    
    if (!value?.target?.value)
    {
      setIsMobileValid(false);
    } 
    else {
      const mobilePhone = value?.target?.value;
      
      const re = new RegExp(/(^8|\+7)((\d{10})|(\s\(\d{3}\)\s\d{3}\s\d{2}\s\d{2}))/);
      const isValid = re.test(mobilePhone.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").replaceAll(" ", ""));
      setIsMobileValid(isValid);
    }
  }

  const branchChange = (value) => {
    console.log(value?.target?.value);
    if (value?.target?.value)
    {
      setIsBranchValid(value?.target?.value != "None");
    } 
  }

  return (
    <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Запись на занятие</Modal.Title>
          </Modal.Header>
          <Form onSubmit={handleSubmit}>
          {!isSent && <Modal.Body>
            <Row className="mb-12">
              <Form.Group as={Col} md="12" controlId="name">
                <Form.Label>Имя участник *</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Имя участника"
                />
                <Form.Control.Feedback type="invalid">
                  Пожалуйста, введите имя
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="12" controlId="age">
                <Form.Label>Возраст участника *</Form.Label>
                <Form.Control
                  required
                  type="number"
                  min="2"
                  max="99"
                  placeholder="Возраст участника"
                />
                <Form.Control.Feedback type="invalid">
                  Пожалуйста, введите возраст
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group as={Col} md="12" controlId="phone">
                <Form.Label>Мобильный телефон *</Form.Label>
                <InputGroup hasValidation>
                  {/* <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text> */}
                  <Form.Control
                    type="text"
                    placeholder="Мобильный телефон"
                    ref={mobileRef}
                    onChange={mobileChange}
                    isInvalid={!isMobileValid}
                    // aria-describedby="inputGroupPrepend"
                    required
                  />
                  {/* <InputMask mask="+7\8 999 99 99 99" maskChar=" " /> */}
                  <Form.Control.Feedback type="invalid">
                    Пожалуйста, введите верный номер телефона.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} md="12" controlId="branch">
                <Form.Label>Филиал *</Form.Label>
                <InputGroup hasValidation>
                  {/* <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text> */}
                  <Form.Control
                    required
                    as="select"
                    onChange={branchChange}
                    isInvalid={!isBranchValid}
                    placeholder='Выберите филиал'
                  >
                    <option value="None">Выберите филиал</option>
                    <option value="Impulse">Impulse | ТРЦ Leo Mall</option>
                    <option value="Energy">Energy | ТРЦ Променад</option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Пожалуйста, укажите филиал.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
            </Row>
            <Row className="mb-12">
              <Form.Group as={Col} md="12" controlId="comments">
                <Form.Label>Комментарии</Form.Label>
                <Form.Control type="text" placeholder="Комментарии" />
              </Form.Group>
            </Row>
            <Form.Group className="mb-12">
              <Form.Label>Нажимая на кнопку "Отправить" Вы соглашаетесь на обработку нами Ваших персональных данных указанных выше.</Form.Label>
            </Form.Group>
          </Modal.Body>}
          {isSent && <Modal.Body>
            <Row className="mb-12">
              <Form.Label>Спасибо за обращение! Мы свяжемся с вами в самое ближайшее время!</Form.Label>
            </Row>
          </Modal.Body>}
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Закрыть
            </Button>
            {!isSent && <Button type="submit" variant="primary">
              Отправить
            </Button>}
          </Modal.Footer>
          </Form>
        </Modal>
    </>
  )}

  export default RequestModelComponent;