import styles from './homePage.module.css';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import React, { useState, useEffect } from "react";
import RequestModelComponent from '../RequestModal/RequestModelComponent';

const HomePage = () => {

  let [showRequestModal, setShowRequestModal] = useState(false);

  const handleClose = () => setShowRequestModal(false);

  const scrollFunc = (ref) => {
    window.scrollTo({ behavior: 'smooth', top: ref?.current?.offsetTop });
  }

  useEffect(() => {
    document.title = 'DEMARCHE CENTRE';
  }, []);

  return (
  <div className={styles.layer}>
    <RequestModelComponent show={showRequestModal} handleClose={handleClose}></RequestModelComponent>
    {/* Десктопная версия */}
    <div className={`row ${styles.p_none} d-none d-md-block`}>
      <div className={`row ${styles.p_none}`}>
          <div className={`row ${styles.p_none} ${styles.mt_70p} justify-content-center`}>
              <div className="col col-md-4">
                <div>
                  <img className={styles.logo_dm_white} src="images/logo_dm_white.png" alt=""/>
                </div>
              </div>
              <div className="col col-md-4">
                <span onClick={() => window.open("https://yandex.ru/maps/-/CDgijJLX", '_blank')} className={styles.header_text}>LIFE - ЖК Окла</span>
                <span> | </span>
                <span onClick={() => window.open("https://yandex.ru/maps/-/CDgij61L", '_blank')} className={styles.header_text}>PULSE - ТРЦ Leo mall</span>
                <span> | </span>
                <span onClick={() => window.open("https://yandex.ru/maps/-/CDgijKi0", '_blank')} className={styles.header_text}>ENERGY - ТРЦ Променад</span>
              </div>
              <div className="col col-md-3">
                <span className={styles.header_text}>+7 (921) 906-27-46</span>
              </div>
            </div>
          </div>
    </div>
    
    {/* Десктоп версия */}
    <div className={`${styles.p_none} row d-none d-md-block`}>
        <div className="col col-md-7"> 
              <div> 
                <img className={styles.ellipse} src="images/ellipse — home.png" alt=""/>
              </div>
              <div> 
                <img className={styles.home_main_image} src="images/home-background.png" alt=""/>
              </div>
        </div>
        <div className={`${styles.text_area} ${styles.mt_50px} col col-md-5`}>
          <div className="row mt-30">
            <div className="row justify-content-center">
              <div className="col col-md-12">
                <div className={styles.text_2}>ЦЕНТР ТАНЦЕВ С <span className="colorffffff">ТОПОВЫМИ</span><br/>ХОРЕОГРАФАМИ СТРАНЫ</div>
                <div className={styles.body_text}>Обучаем детей и взрослых с нуля до профессионального уровня самыми модными танцевальными направлениями. Утренние, вечерние и группы выходного дня. Учись, когда тебе удобно.</div>
              </div>
            </div>
            <div className="justify-content-left">
              <a href='/Children'><span className={`${styles.deti} ${styles.m_20}`}>Дети</span></a>
              <a href='/Adults'><span className={`${styles.vzroslye} ${styles.m_20}`}>Взрослые</span></a>
            </div>
          </div>   
          <div className={`row`}>
            <div className="row justify-content-center">
                <div className={styles.social_media}>
                  <div className={`${styles.icon} ${styles.group}`}>
                        <a href="https://www.instagram.com/demarche.centre"><img src="images/instagram.png"  width="35"/></a>
                        <a href="https://vk.com/demarche_dance"><img src="images/vk.png" alt="" width="35"/></a>
                        <a href="https://t.me/@DM-centre"><img src="images/telegram.png"  width="35"/></a>
                        <a href="https://t.me/@DM-centre"><img src="images/whatsapp.png" alt="" width="35"/></a>
                  </div>
                </div>
            </div>
          </div>
          <div>
            <div className={styles.zapisatsya} onClick={() => setShowRequestModal(true) }>ЗАПИСАТЬСЯ</div>
          </div>
        </div>
    </div>

    {/* Мобильная версия  */}
    <div className={`row d-block d-md-none`}>
      <div className="row"> 
        <div className="col col-md-12">
            <img className={styles.logo_dm_white_mobile} src="images/logo_dm_white.png" alt=""/>
            <span ><a className={styles.header_text_mobile} href="callto:89219062746">+7 (921) 906-27-46</a></span>
        </div>
      </div>
      <div className="row justify-content-center"> 
        <div> 
          <img className={styles.home_main_image_mobile} src="images/home-background.png" alt=""/>
        </div>
      </div>
      <div className="row justify-content-center">
            <div className={styles.text_2_mobile}>ЦЕНТР ТАНЦЕВ С <span className="colorffffff">ТОПОВЫМИ</span><br/>ХОРЕОГРАФАМИ СТРАНЫ</div>
      </div>
      <div className="row justify-content-center">
        <div className={styles.home_buttons}>
          <a href='/Children'><span className={`${styles.deti_mobile}`}>Дети</span></a>
          <a href='/Adults'><span className={`${styles.vzroslye_mobile}`}>Взрослые</span></a>
        </div>
      </div>
    </div>
  </div>
      
  );
}

export default HomePage;